import React from 'react';
import { ChakraProvider, Box, Link, VStack, Heading } from '@chakra-ui/react';

function App() {
    return (
        <Box p={5}>
        <VStack spacing={4}>
            <Link href="/">Home</Link>
            <Link href="/qrlogin">QR Login</Link>
            <Link href="/qrsample">QR Sample</Link>
        </VStack>
    </Box>
    );
}

export default App;