import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Text } from '@chakra-ui/react';
import { v4 as uuidv4 } from "uuid";
const QRSample = () => {

    return (
        <VStack spacing={4} align="stretch">
            {[...Array(10)].map((_, rowIndex) => (
                <Box key={rowIndex} display="flex" gap={4}>
                    {[...Array(2)].map((_, colIndex) => (
                        <Box key={colIndex} border="1px" borderColor="gray.200" p={4} borderRadius="md" backgroundColor="white" flex="1" display={"flex"} gap={"30px"}>
                            <img src={"https://api.qrserver.com/v1/create-qr-code/?data=https://wybory.web.fuki.edu.pl/?id=ZS2-" + uuidv4().slice(0, 8) + "&size=200x200"} alt="QR Code" />
                            <Box>
                                <Text color="black" fontSize="xl">Wybory do Młodziezowej Rady Gminy | ZS2</Text>
                                <Text color="black">ID Anonimowego wyborcy: ZS2-{uuidv4().slice(0, 8)}</Text>
                                <Text color="black">W celu oddania głosu zeskanuj kod QR</Text>
                                <Text color="black">lub wejdź na stronę: wybory.web.fuki.edu.pl/votelogin</Text>
                            </Box>
                        </Box>
                    ))}
                </Box>
            ))}
        </VStack>
    );
};

export default QRSample;