import React from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';

const QRLogin = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle login logic here
    };

    return (
        <Box maxW="sm" mx="auto" mt={10} p={6} borderWidth={1} borderRadius="lg" boxShadow="lg">
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    <FormControl id="voter-id" isRequired>
                        <FormLabel>ID Anonimowego Wyborcy</FormLabel>
                        <Input type="text" placeholder="Wprowadź swoje ID" />
                    </FormControl>
                    <Button type="submit" colorScheme="blue" width="full">
                        Zaloguj się
                    </Button>
                </VStack>
            </form>
        </Box>
    );
};

export default QRLogin;