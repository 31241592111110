import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
} from "@chakra-ui/react";

function App() {
  const [selectedMember, setSelectedMember] = useState(null);
  const [votedMember, setVotedMember] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const members = [
    "Jan Kowalski | ZS2",
    "Anna Nowak | ZS2",
    "Piotr Wojcik | ZS2",
    "Katarzyna Kowalczyk | ZS2",
    "Andrzej Kaminski | ZS2",
    "Maria Lewandowski | ZS2",
    "Tomasz Zielinski | ZS2",
    "Agnieszka Szymanski | ZS2",
    "Pawel Dabrowski | ZS2",
    "Barbara Ostrowski | ZS2",
    "Marek Kaczmarek | ZS2",
    "Ewa Piotrowski | ZS2",
    "Grzegorz Grabowski | ZS2",
    "Magdalena Zajac | ZS2",
    "Rafal Pawlak | ZS2",
    "Joanna Michalski | ZS2",
    "Krzysztof Adamczyk | ZS2",
    "Monika Dudek | ZS2",
    "Mariusz Nowicki | ZS2",
    "Dorota Jankowski | ZS2",
    "Wojciech Mazur | ZS2",
    "Beata Kwiatkowski | ZS2",
    "Jacek Wojciechowski | ZS2",
    "Aleksandra Krawczyk | ZS2",
    "Zbigniew Kaczmarczyk | ZS2",
    "Izabela Piasecki | ZS2",
    "Jerzy Wrobel | ZS2",
    "Natalia Krupa | ZS2",
    "Stanislaw Wieczorek | ZS2",
    "Sylwia Stasiak | ZS2",
  ];

  const handleVote = (member) => {
    setSelectedMember(member);
    onOpen();
  };

  const confirmVote = () => {
    setVotedMember(selectedMember);
    console.log(`Voted for: ${selectedMember}`);
    onClose();
  };

  return (
    <Box p={4} display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" maxWidth="600px" mt={"30px"}>
        <Text mb={"30px"} fontSize={"xl"}>
          ID Anonimowego wyborcy: ZS2-{uuidv4().slice(0, 8)}
        </Text>
        {votedMember ? (
          <>
          <Text mb={"30px"} fontSize={"xl"}>
            Zagłosowano na:{" "}
            <Text as="span" fontWeight="bold">
              {selectedMember}
            </Text>
          </Text>
          <Text mb={"30px"} fontSize={"xl"}>
            Dziękujemy za oddanie głosu!
          </Text>
          </>
        ) : (
          <List spacing={3}>
            {members.map((member, index) => (
              <React.Fragment key={member}>
                <ListItem
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  #{index} | {member}{" "}
                  <Button width="150px" onClick={() => handleVote(member)}>
                    Zaglosuj na #{index}
                  </Button>
                </ListItem>
                {index < members.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </List>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Potwierdzenie glosowania</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Jesteś pewnien, ze chcesz zaglosowac na
            <Text fontWeight="bold"> {selectedMember}?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={confirmVote}>
              Tak
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Nie
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default App;
